import React from 'react'
import './base.css'
import styles from './home-layout.module.css'
import { Helmet } from 'react-helmet'

import Container from './container'
import Header from './header'
import Footer from './footer'

class Template extends React.Component {
  render() {
    const { children, location, packageTypes, photoshoots } = this.props

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    return (
      <>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css2?family=Manrope:wght@500;600;700&display=swap"
            rel="stylesheet"
          />

          <title>Juliana de Souza Photography</title>

          <script
            id="Cookiebot"
            src="https://consent.cookiebot.com/uc.js"
            data-cbid="8772c6d2-7a4f-4524-b771-8d5196b6fc4f"
            data-blockingmode="auto"
            type="text/javascript"
          ></script>

          <script
            data-cookieconsent="ignore"
            src="/default-consent.js"
          ></script>

          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-X222HY18ND"
          ></script>

          <script src="/analytics.js"></script>
        </Helmet>
        <Container>
          <Header
            location={location}
            packageTypes={packageTypes}
            photoshoots={photoshoots}
          />

          <div className={styles.content}>
            <div>{children}</div>
          </div>
        </Container>

        <Footer photoshoots={photoshoots} />
      </>
    )
  }
}

export default Template
