import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import HomeLayout from '../components/home-layout'
import { Helmet } from 'react-helmet'
import Masonry from 'react-masonry-css'

class RootIndex extends React.Component {
  render() {
    const images = get(this, 'props.data.allContentfulPhoto.edges', [])

    return (
      <HomeLayout
        location={this.props.location}
        packageTypes={this.props.data.allContentfulPackageType}
        photoshoots={this.props.data.contentfulEntriesGroup}
      >
        <Helmet>
          <meta
            name="description"
            content="Couple and family photoshoots, Rotterdam"
          />
        </Helmet>

        <Masonry
          breakpointCols={2}
          className="masonry-grid"
          columnClassName="masonry-grid_column"
        >
          {images.map((edge, index) => (
            <div
              key={index}
              style={{
                position: 'relative',
                paddingBottom: getPaddingBottom(
                  edge.node.image.fluid.aspectRatio
                ),
              }}
            >
              <img
                src={`${edge.node.image.file.url}?w=660`}
                srcSet={`${edge.node.image.file.url}?w=660 660w, ${edge.node.image.file.url}?w=990 990w, ${edge.node.image.file.url}?w=1320 1320w`}
                loading="lazy"
                style={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>
          ))}
        </Masonry>
      </HomeLayout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query MyQuery {
    allContentfulPackageType {
      nodes {
        name
        slug
      }
    }

    allContentfulPhoto(sort: { fields: order, order: ASC }) {
      edges {
        node {
          id
          image {
            file {
              url
            }
            fluid {
              aspectRatio
            }
          }
        }
      }
    }

    contentfulEntriesGroup(type: { eq: "Photoshoot" }) {
      items {
        name
        slug
      }
    }
  }
`

function getPaddingBottom(aspectRatio) {
  const fixedAspectRatio = aspectRatio.toFixed(2)

  if (fixedAspectRatio === '1.50') {
    return '66.6%'
  }

  return '150%'
}
